import React from 'react';
import Router from "./routes/sections";
import './App.css';

/* i18n translation variables */
import './assets/i18n/i18n';
import {DataProvider} from "./components/data";

function App() {
    return (
        <DataProvider>
            <div className="App">
                <Router/>
            </div>
        </DataProvider>
    );
}

export default App;
